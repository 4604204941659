import React from 'react';
import Footer from './Footer/Footer';
import NavBar from './Header/NavBar';
import { FullScreen, useFullScreenHandle } from "react-full-screen";


const UserfulPageLayout = (props) => {
    const handle = useFullScreenHandle();

    return (
        <>
            <FullScreen handle={handle}>
                <NavBar {...props}  handle={handle} />
                {/* <DisconnectOverlay disabled={props.hideDisconnectOverlay}/> */}
                {props.children}
                <Footer version={props.version} />
            </FullScreen>
        </>
    );
}

export default UserfulPageLayout;