import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useWebSocket, { Options as WebSocketOptions} from "react-use-websocket";
import { RTCPeerInfo } from "../webrtc/WebRTCCaptureMrg";

const SAPPHIRE_API = "sapphire-api";
const POSSIBLE_PREFIXES = ["meet.", "sapphire."]

export interface ISapphirePortalComm {
    availableMeetingRooms: string[];
    registerClient: () => void;
    webrtcAnswer: RTCPeerInfo;
    isMeetingRoomAvailable: (meetingRoomID: string) => Promise<boolean>;
    isMeetingRoomOccupied: (meetingRoomID: string) => Promise<boolean>;
    updateCastingMeetingRoom: (isCasting: boolean, meetingRoomID: string, guestName: string) => void;
    sendOfferForMeetingRoom: (meetingRoomID: string, guestName: string, offer: RTCPeerInfo) => void;
    sendCloseConnection: (meetingRoomID: string) => void;
}

export default function useSapphirePortalComm(options: {onOpen:()=>void} = null):ISapphirePortalComm {
    let webSocketOptions:WebSocketOptions = {
        share: true,
        shouldReconnect: (closeEvent) => {
            console.log("websocket closed. closeEvent=" + JSON.stringify(closeEvent));
            return true;
        },
        reconnectAttempts: 1000,
        reconnectInterval: 3000,
    };
    if (options) {
        webSocketOptions.onOpen = options.onOpen;
    }

    const sapphireWebsocketURL = useMemo(() => {
        const currentPageHost = window.location.hostname;
        const currentPagePort = window.location.port;
        if (currentPageHost === "localhost") {
            // This is in a dev enviornment. 
            // Use Tony test Sapphire api.
            //return "wss://sapphire-api.tony.userful.dev"
            //return "ws://localhost:8080"
            return "wss://testnet7.userful.ca/sapphire-service";
        }
        const pathname = window.location.pathname;
        console.log("pathname: " + pathname);
        if (pathname.startsWith("/meet")) {
            // This is air-gapped.
            return "wss://" + currentPageHost + (currentPagePort ? ":" + currentPagePort : "") + "/sapphire-service";
        } else {
            // This is cloud solution.
            let suffix = currentPageHost;
            for (const prefix of POSSIBLE_PREFIXES) {
                const i = currentPageHost.indexOf(prefix);
                if (i >= 0) {
                    suffix = currentPageHost.substring(i + prefix.length);
                    break;
                }
            }

            console.log("suffix:" + suffix);        
            return "wss://" + SAPPHIRE_API + "." + suffix;
        }
    }, []);

    console.log("sapphireWebsocketURL:" + sapphireWebsocketURL);     

    const {sendJsonMessage, lastJsonMessage} = useWebSocket(sapphireWebsocketURL, webSocketOptions);
    const [availableMeetingRooms, setAvailableMeetingRooms] = useState<string[]>([]);
    const [webrtcAnswer, setWebrtcAnswer] = useState<RTCPeerInfo>(null);

    const apiCallResponsePromiseRef = useRef<{}>({});

    const genCallID = useCallback(() => {
        const length = 6;
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }, [])

    const registerClient = useCallback(() => {
        console.log("Register sapphire client...");
        let command = {action: "registerClient", "clientType": "portal"};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const isMeetingRoomAvailable = useCallback((meetingRoomID:string) => {
        console.log("isMeetingRoomAvailable...");
        const callID = genCallID();
        let command = {action: "isMeetingRoomAvailable", meetingRoomID, callID};
        sendJsonMessage(command);

        return new Promise<boolean>((resolve, reject) => {
            apiCallResponsePromiseRef.current[callID] = {resolve, reject};
        })        
    }, [sendJsonMessage]);

    const isMeetingRoomOccupied = useCallback((meetingRoomID:string) => {
        console.log("isMeetingRoomOccupied...");
        const callID = genCallID();
        const command = {action: "isMeetingRoomOccupied", meetingRoomID, callID};
        sendJsonMessage(command);

        return new Promise<boolean>((resolve, reject) => {
            apiCallResponsePromiseRef.current[callID] = {resolve, reject};
        })        
    }, [sendJsonMessage]);

    const updateCastingMeetingRoom = useCallback((isCasting:boolean, meetingRoomID:string, guestName:string) => {
        console.log("updateCastingMeetingRoom...");
        let command = {action: "updateCastingMeetingRoom", isCasting, meetingRoomID, guestName};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const sendOfferForMeetingRoom = useCallback((meetingRoomID:string, guestName: string, offer:RTCPeerInfo) => {
        console.log("Send offer...");
        let command = {action: "sendOffer", meetingRoomID, guestName, offer};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const sendCloseConnection = useCallback((meetingRoomID: string) => {
        console.log("Close connection...");
        let command = {action: "closeConnection", meetingRoomID};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    useEffect(() => {
        if (lastJsonMessage) {
            console.log("lastJsonMessage=" + JSON.stringify(lastJsonMessage));
            const result = lastJsonMessage;
            if (typeof result === "object") {
                if (result.action) {
                    if (result.action === "responseAvailableMeetingRooms") {
                        if (result.availableMeetingRooms && Array.isArray(result.availableMeetingRooms)) {
                            setAvailableMeetingRooms(
                                result.availableMeetingRooms.map(o => {
                                    return o.meeting_room_id;
                                })
                            );
                        }
                    } else if (result.action === "relayAnswer") {
                        if (typeof result.answer === "object") {
                            console.log("setWebrtcAnswer");
                            setWebrtcAnswer(result.answer);
                        }
                    } else if (result.action === "responseIsMeetingRoomAvailable") {
                        if (typeof result.available === "boolean") {
                            console.log("responseIsMeetingRoomAvailable: " + result.available);
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].resolve(result.available);
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        } else {
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].reject("no available field");
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        }
                    } else if (result.action === "responseIsMeetingRoomOccupied") {
                        if (typeof result.occupied === "boolean") {
                            console.log("responseIsMeetingRoomOccupied: " + result.occupied);
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].resolve(result.occupied);
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        } else {
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].reject("no occupied field");
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        }
                    }
                }
            }
        }
    }, [lastJsonMessage]);

    return {availableMeetingRooms, registerClient, isMeetingRoomAvailable, isMeetingRoomOccupied, updateCastingMeetingRoom, webrtcAnswer, sendOfferForMeetingRoom, sendCloseConnection};
}