import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './sourcesform.scss';
import './InputPanel.scss';


interface IProps {
    meetingRoomID:string,
    endCasting:()=>void,
    isCasting: boolean,
}

export default function SharingPanel(props: IProps) {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.isCasting) {
            navigate("/casting");
        } else {
            navigate("/");
        }
    },[props.isCasting])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: "1" }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img src="./assets/no-user.svg" alt='no-user' />
                <div style={{ fontWeight: '600', color: '#1F2747', fontSize: '20px' }}>Screen share in progress</div>
                <span style={{ color: '#1F2747', marginBottom: '24px' }}>
                    You are currently sharing your screen to “{props.meetingRoomID}”
                </span>
                <button className="button-secondary" onClick={props.endCasting}>End Screen Share</button>
            </div>
        </div>

    )
    
}