import React from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './navbar.scss';
import { getAppFromAppID } from './HeaderAppConfig';

interface IProps {
    currentApp: string,
}

export default function NavBarTitle(props: IProps) {
    const titleContainer = (icon, title) => {
        return (<div className="navbarTitle"> <FontAwesomeIcon icon={faAngleRight} /> {icon} {title}</div>)
    }
    const app = getAppFromAppID(props.currentApp);

    return app ?
        titleContainer(app.titleIcon, app.title) : (<div></div>);
}

