import React, { useState, useRef, useEffect } from 'react'
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faQuestionCircle, faSearch, faUserCircle, faHome, faLightbulb, faExpand, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './navbar.scss';
import NavBarTitle from './NavBarTitle';
import { getAppFromAppID } from './HeaderAppConfig';

library.add(faExpand, faBolt, faQuestionCircle, faSearch, faHome, faQuestionCircle, faUserCircle, faLightbulb, faCircleNotch);

function NavBar(props) {
    const [showSearch, setShowSearch] = useState(props.showSearch || false);
    const [showHome, setShowHome] = useState(props.showHome || false);
    const [showLightBulb, setShowLightBulb] = useState(props.showLightBulb || false);
    const [showHelp, setShowHelp] = useState(props.showHelp || false);
    const [showBolt, setShowBolt] = useState(props.showBolt || false);
    const [showWindowMax, setShowWindowMax] = useState(props.showWindowMax || false);
    const [loggingOut, setLoggingOut] = useState(false);

    type CustomToggleProps = {
        children?: React.ReactNode;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
        className?: string | undefined;
    };
    

    const CustomToggle = React.forwardRef(
        (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
            <a
                href=""
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(e);
                }}
                ref={ref}
                className={props.className}
            >
                {props.children}
            </a>
        ));

    const toggleFullScreen = (value) => {
        console.log("Fullscreen value", value);
        const { exit, enter, active } = value;
        if (active === true) {
            exit();
        }
        if (active === false) {
            enter();
        }
    }

    const currentApp = "Sapphire";
    const app = getAppFromAppID(currentApp);
    console.warn(app);
    return (
        <Navbar>
            <Container>
                <div style={{ display: "flex" }}>
                    <Navbar.Brand href="https://www.userful.com">
                        <img src="./images/Userful_Logo_Horizontal-Color.svg" alt="Userful"></img>
                    </Navbar.Brand>
                    <NavBarTitle currentApp={currentApp}/>
                </div>
                <div className="button-group nav-leftcontent-wrapper">
                    <Nav className="nav-icon-container">
                        {showSearch && <a href="#" title="Search"><FontAwesomeIcon icon="search" /></a>}
                        {showHome && <a href="#" title="Home"><FontAwesomeIcon icon="home" /></a>}
                        {showLightBulb && <a href="#" title="Search"><FontAwesomeIcon icon="lightbulb" /></a>}
                        {showHelp && <a href={`https://userful.clickhelp.co/articles/userful-12-1-online-help${app?.helpURI}`} target="_blank" title="Open documentation"><FontAwesomeIcon icon="question-circle" /></a>}
                        {showBolt && <a href="#"><FontAwesomeIcon icon="bolt" /></a>}
                        {showWindowMax && <a onClick={() => toggleFullScreen(props.handle)} title="Fullscreen"><FontAwesomeIcon icon="expand" /></a>}
                    </Nav>
                    {/* <Dropdown id="UserAccount" className="user-account" drop="down" align="end">
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-user-account">
                            <FontAwesomeIcon icon="user-circle" className="user-account-icon" title="User" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu as="ul">
                            <Dropdown.Item as="li">
                                <a href="#">Account Details</a>
                            </Dropdown.Item>
                            <hr style={{ margin: "8px" }} />
                            {
                                loggingOut ?
                                    <Dropdown.Item as="li">
                                        <FontAwesomeIcon icon="circle-notch" spin />
                                    </Dropdown.Item>
                                    :
                                    <Dropdown.Item as="li" onClick={() => logoutAndRefreshPage()}>
                                        <a href="#">Logout</a>
                                    </Dropdown.Item>
                            }

                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
            </Container>
        </Navbar>
    )
}

export default NavBar
