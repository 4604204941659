import { faCog, faMapMarkerAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const AppConfig = {
    diamond: {
        title: "Diamond Control Rooms",
        titleIcon: (<img alt='D' src="./assets/diamond.svg" />),
        helpURI: "/diamond-control-room",
    },
    emerald: {
        title: "Emerald Corporate Signage",
        titleIcon: (<img alt='E' src="./assets/emerald.svg" />),
        helpURI: "/emerald-corporate-signage",
    },
    topaz: {
        title: "Topaz Business Intelligence",
        titleIcon: (<img alt='T' src="./assets/topaz.svg" />),
        helpURI: "/topaz-business-intelligence",
    },
    amethyst: {
        title: "Amethyst Artistic",
        titleIcon: (<img alt='A' src="./assets/amethyst.svg" />),
        helpURI: "/amethyst-artistic",
    },
    sapphire: {
        title: "Spaces Meeting Rooms",
        titleIcon: (<img alt='S' src="./assets/sapphire.svg" />),
        helpURI: "/sappire-meeting-room",
    },
    ruby: {
        title: "Ruby Remote Worker",
        titleIcon: (<img alt='R' src="./assets/ruby.svg" />),
        helpURI: "/ruby-remote-worker",
    },
    config: {
        title: "System Configuration Settings",
        titleIcon: (<FontAwesomeIcon icon={faCog} />),
        helpURI: "/system-configuration",
    },
    sourcesAndDestinations: {
        title: "Source & Destination Configuration",
        titleIcon: (<FontAwesomeIcon icon={faMapMarkerAlt} />),
        helpURI: "/source-destination-configuration",
    },
    userManagement: {
        title: "Users Administration",
        titleIcon: (<FontAwesomeIcon icon={faUsers} />),
        helpURI: "/user-management",
    },
    initialSetup: {
        title: "Initial Setup",
        titleIcon: (<FontAwesomeIcon icon={faCog} />),
        helpURI: "/initial-setup",
    },
}


export const getAppFromAppID = (appIDValue) : {
    title: string,
    titleIcon: any,
    helpURI: string,
} => {
    switch (appIDValue) {
        case "ControlRoom":
        case "Diamond":
        case "ControlRoomMgr":
            return AppConfig.diamond;
        case "Emerald":
            return AppConfig.emerald;
        case "Topaz":
            return AppConfig.topaz;
        case "Amethyst":
            return AppConfig.amethyst;
        case "Sapphire":
            return AppConfig.sapphire;
        case "Ruby":
            return AppConfig.ruby;
        case "Config":
            return AppConfig.config;
        case "Calibration":
            return AppConfig.sourcesAndDestinations;
        case "realm-management":
        case "AetherManager":
            return AppConfig.userManagement;
        case "Setup":
            return AppConfig.initialSetup;
        default:
            return null;
    }
}