import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSapphirePortalComm, { ISapphirePortalComm } from "../comm/SapphirePortalComm";

export interface RTCPeerInfo {
    description:RTCLocalSessionDescriptionInit, 
    candidates:RTCIceCandidate[],
    senderWSConnectionID:string,
    rtcConnectionID:string
};

const DEFAULT_ICE_SERVER = {
    urls : "turn:turn.userful.com",
    username : "userful",
    credential : "sh37wu"
};

export default function useWebRTCCaptureMrg(
		meetingRoomID:string, 
		guestName:string, 
		sapphirePortalComm:ISapphirePortalComm) {

    const streamRef = useRef<MediaStream>();
	const rtcConnectionRef = useRef<RTCPeerConnection>();
    const isWaitingForAnswerRef = useRef<boolean>(false);

	const [isCasting, setCasting] = useState<boolean>(false);

	const startCasting = useCallback(() => {
		function createConnection() {
			let rtcConnection = rtcConnectionRef.current;
			if (rtcConnection) {
				rtcConnection.close();
			}

			let config:RTCConfiguration = {iceServers: [DEFAULT_ICE_SERVER]};

			const pathname = window.location.pathname;
			console.log("pathname: " + pathname);
			if (pathname.startsWith("/meet")) {
				// This is air-gapped.		
				console.log("This is air-gapped.");		
				config = {iceServers: []};
			} 
			console.log("use ice config:", config);
			let localDescription:RTCLocalSessionDescriptionInit = null;
	
			rtcConnection = rtcConnectionRef.current = new RTCPeerConnection(config);
	
			const icecandidates:RTCIceCandidate[] = [];
			let isSentOffer = false;
			rtcConnection.onicecandidate = event => {
				console.debug("onicecandidate");
				if (event.candidate && event.candidate.candidate) {
					icecandidates.push(event.candidate);
				} else if (!isSentOffer) {
					// All candidates gethered.
					console.debug("candidatesJSON:" + JSON.stringify(icecandidates));
					sendOffer(localDescription, icecandidates);	
					isSentOffer = true;		
				}
			}; 
			
			setTimeout(() => {
				// All candidates gethered.
				console.debug("candidatesJSON:" + JSON.stringify(icecandidates));
				if (!isSentOffer) {
					sendOffer(localDescription, icecandidates);	
					isSentOffer = true;	
				}
			}, 3000);	
			
	
			streamRef.current.getTracks().forEach(track => {
				console.log("Adding track: " + track.label);
				rtcConnection.addTrack(track, streamRef.current);		       
			});
	
			rtcConnection.onconnectionstatechange =  e => {
				let state = rtcConnection.connectionState;				
				console.log("onconnectionstatechange. connectionState=" + state);
				if (state === "connected") {
					setCasting(true);
				// Somehow this event won't be triggered if we close the connection ourselves. 
				// So use a timer to check the connection status instead.
	//			} else if (state === "disconnected" 
	//				|| state === "failed"
	//				|| state === "closed") {
	//				theInstance.@com.userful.ucc.client.pres.WebRTCCaptureMrg::endCapture()();
	//				theInstance.@com.userful.ucc.client.pres.WebRTCCaptureMrg::showInputPanel()();	
				}
			};
			  
			streamRef.current.addEventListener('inactive', event => {
				console.log("Capture stream inactive. Closing the RTPconnection.");
				rtcConnection.close();
			});
	
			let checkingInterval = setInterval(() => {
				console.log("connectionState=" + rtcConnection.connectionState);
				if (rtcConnection.connectionState === "disconnected" 
						|| rtcConnection.connectionState === "failed" 
						|| rtcConnection.connectionState === "closed") {
					clearInterval(checkingInterval);
					endCapture();
					setCasting(false);			
				}
			}, 1000);	
			
			const options = {
				offerToReceiveAudio: true, 
				offerToReceiveVideo: true
			};
			rtcConnection.createOffer(options).then(
				desc => {
					console.debug("desc:" + desc);
					rtcConnection.setLocalDescription(desc);
					localDescription = desc; 
				},
				error => {
					console.error('Failed to create session description: ', error);
				}
		
			);
		}
		
		function sendOffer(description:RTCLocalSessionDescriptionInit, candidates:RTCIceCandidate[]) {
		
			console.debug("Sending Offer:");
			console.debug("Description:" + JSON.stringify(description));
			console.debug("Candidate:" + JSON.stringify(candidates));
			
			const offer:RTCPeerInfo = {
				description: description,
				candidates: candidates,
				senderWSConnectionID: null,
				rtcConnectionID: null
			}
	
			sapphirePortalComm.sendOfferForMeetingRoom(meetingRoomID, guestName, offer);
	
			console.log("isWaitingForAnswer=" + isWaitingForAnswerRef.current);
			isWaitingForAnswerRef.current = true;
		}

		if (meetingRoomID) {
			navigator.mediaDevices.getDisplayMedia({video: true}).then(
				stream => {
					console.log("get capture stream");
					//videoElement.srcObject = stream;
					streamRef.current = stream;
					createConnection();
				}, 		    
				error => {
					console.error('Failed to capture the screen: ' + error.toString());
				}
			);
		}
		
	}, [meetingRoomID, sapphirePortalComm.sendOfferForMeetingRoom, endCapture])

	function endCapture() {
		if (streamRef.current) {
			let tracks = streamRef.current.getTracks();
			tracks.forEach(track => track.stop());
		}
		rtcConnectionRef.current.close();
		sapphirePortalComm.sendCloseConnection(meetingRoomID);
	}

	const endCasting = useCallback(() => {
		endCapture();
	}, [endCapture]);

	useEffect(() => {
		console.log("gotWebrtcAnswer: isWaitingForAnswer=" + isWaitingForAnswerRef.current);
		if (sapphirePortalComm.webrtcAnswer) {
			if (!isWaitingForAnswerRef.current) {
				return;
			}
			const rtcConnection = rtcConnectionRef.current;
			if (rtcConnection && rtcConnection.signalingState === "stable") {
				console.warn("connection is stable. Not doing anything");
				return;
			}		
			
			console.log("setRemoteDescription");
			rtcConnection.setRemoteDescription(new RTCSessionDescription({type: sapphirePortalComm.webrtcAnswer.description.type, sdp: sapphirePortalComm.webrtcAnswer.description.sdp})).then(() => {
				sapphirePortalComm.webrtcAnswer.candidates.forEach(candidate => {
					console.log("addIceCandidate");
					rtcConnection.addIceCandidate(new RTCIceCandidate(candidate));
				});
			});

			isWaitingForAnswerRef.current = false;
		}
    }, [sapphirePortalComm.webrtcAnswer])


	return {startCasting, endCasting, isCasting};
}

