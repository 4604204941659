import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './sourcesform.scss';
import './InputPanel.scss';
import MultiConnectionWarningModal, { WrongMeetingRoomModal } from "./Modal";
import { useEffect } from "react";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: '#1F2747',
        maxHeight: '176px',
        height: '32px',
        padding: '0px 16px',
        background: state.isSelected ? '#F3F4F5' : state.isFocused ? '#F3F4F5' : undefined,
        display: 'flex',
        alignItems: 'center',
        "&:active": {
            background: '#F3F4F5',
        }

    }),
    control: (base, state) => ({
        // none of react-select's styles are passed to <Control />
        ...base,
        width: "100%",
        height: '36px',
        color: '#1F2747',
        background: ' #FFFFFF',
        boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
        borderRadius: '8px',
        transition: 'none',
        display: 'flex',
        border: state.menuIsOpen ? '1px solid #6C89E8' : '1px solid #C6C8CE',
        '&:hover': {
            border: '1px solid #4B5168',
        }
    }),
}

interface IProps {
    availableMeetingRooms: string[],
    meetingRoomID: string,
    setMeetingRoomID: Dispatch<SetStateAction<string>>,
    guestName: string,
    setGuestName: Dispatch<SetStateAction<string>>,
    isMeetingRoomAvailable: (string) => Promise<boolean>,
    isMeetingRoomOccupied: (string) => Promise<boolean>,
    startCasting: () => void,
    isCasting: boolean
}

export default function InputPanel(props: IProps) {
    const [showMultipleConnectionsWarning, setShowMultipleConnecctionsWarning] = useState(false);
    const [showWrongMeetingRoomWarning, setShowWrongMeetingRoomWarning] = useState(false);
    const [selectValue, setSelectValue] = useState(null);
    const [selectConferenceTool, setConferenceTool] = useState("uCast");

    // Try to retrieve meeting room id from the URL query.
    const urlParams = new URLSearchParams(window.location.search);
    const uriID = urlParams.get("id");
    console.log("uriID:", uriID); 
    let defaultMeetingRoomID = "";
    if (uriID) {
        defaultMeetingRoomID = decodeURI(uriID);
    } else {
        // Try to retrieve meeting room id from the URL path.
        let pathname = window.location.pathname
        if (pathname.startsWith("/meet")) {
            pathname = pathname.substring("/meet".length);            
        }
        if (pathname.startsWith("/")) {
            pathname = pathname.substring(1);            
        }
        defaultMeetingRoomID = decodeURI(pathname);
    }

    console.log("defaultMeetingRoomID:" + defaultMeetingRoomID); 

    const navigate = useNavigate();
    function handleChange(
        newValue: any,
        actionMeta: any
    ) {
        console.log("handleChange() - newValue:", newValue);
        console.log("actionMeta:", actionMeta);
        if (actionMeta.action === "clear") {
            setSelectValue(null);
        }
        if (newValue) {
            console.log("set value: ", newValue);
            setSelectValue(newValue);
            props.setMeetingRoomID(newValue ? newValue.value : "");
        }
    };

    function handleInputChange(value, action) {
        console.log("handleInputChange() - inputValue:", value);
        console.log("action:", action);
        if (action.action === "input-blur") {
            if (action.prevInputValue) {
                console.log("setMeetingRoomID:", action.prevInputValue)
                setSelectValue({ value: action.prevInputValue, label: action.prevInputValue });
                props.setMeetingRoomID(action.prevInputValue);
            }
        }
    }

    function handleGuestNameChange(event: any) {
        let value: string = event.target.value;
        if (value.length > 10) {
            value = value.substring(0, 10) + "...";
        }
        props.setGuestName(value);
    }

    function handleJoinButtonClick() {
        if (!props.meetingRoomID) {
            console.log("handleJoinButtonClick:H0")
            setShowWrongMeetingRoomWarning(true);
            return;
        }

        props.isMeetingRoomAvailable(props.meetingRoomID).then(avaiable => {
            if (avaiable) {
                console.log("handleJoinButtonClick:H1")
                return props.isMeetingRoomOccupied(props.meetingRoomID);
            } else {
                console.log("handleJoinButtonClick:H2")
                setShowWrongMeetingRoomWarning(true);
                return Promise.reject();
            }
        }).then(occupied => {
            if (occupied) {
                console.log("handleJoinButtonClick:H3")
                setShowMultipleConnecctionsWarning(true);
            } else {
                console.log("handleJoinButtonClick:H4")
                props.startCasting();
            }
            
        });

    }

    useEffect(() => {
        if (props.isCasting) {
            navigate("/casting");
        } else {
            navigate("/");
        }
    }, [props.isCasting])

    return (
        <div className="main-container">
            <div className="source-form-wrapper">
                <Form className="source-form" onSubmit={event => event.preventDefault()}>
                    <div>
                        <div className="info-heading">Select a Meeting Room</div>
                        <span className="heading2">You will be prompted to share your screen upon joining.</span>
                    </div>

                    <Form.Group className="input-wrapper" controlId="formSourceType">
                        <Form.Label className="heading1">Meeting Room&nbsp;
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </Form.Label>
                        <CreatableSelect
                            styles={customStyles}
                            isSearchable={true}
                            isClearable={true}
                            autoFocus={true}
                            noOptionsMessage={() => "Please select or type Meeting Room"}
                            placeholder="Please select or type Meeting Room"
                            formatCreateLabel={(inputValue) => ("Type Meeting Room: " + inputValue)}
                            defaultInputValue={defaultMeetingRoomID}
                            onChange={handleChange}
                            value={selectValue}
                            onInputChange={handleInputChange}
                            options={props.availableMeetingRooms.map(v => { return { value: v, label: v }; })} />
                    </Form.Group>

                    <Form.Group className="input-wrapper" controlId="formSourceName">
                        <Form.Label className="heading1">Guest Name&nbsp;
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </Form.Label>
                        <Form.Control as="input" name="guestName" value={props.guestName} onChange={handleGuestNameChange}>
                        </Form.Control>
                    </Form.Group>

                    <div className="videoConferencingContainer">
                        <div className="headerText">
                            <div className="info-heading">Video Conferencing</div>
                            <span className="heading2">Select a conferencing tool to launch if needed.</span>
                        </div>
                        <div className='content'>

                            {selectConferenceTool === "uCast" &&
                                <div className="videoIconWrapper">
                                    <img alt="userful logo" src="./assets/userful-logo.svg" />
                                    <span>uCast</span>
                                </div>
                            }

                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip>
                                        Coming Soon
                                    </Tooltip>
                                }
                            >
                                <div className="videoIconWrapper disabled">
                                    <img alt="google meet icon" src="./assets/zoom.svg" />
                                    <span>Zoom</span>
                                </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip>
                                        Coming Soon
                                    </Tooltip>
                                }
                            >
                                <div className="videoIconWrapper disabled">
                                    <img alt="microsoft team icon" src="./assets/ms-team.svg" />
                                    <span>Microsoft Teams</span>
                                </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip>
                                        Coming Soon
                                    </Tooltip>
                                }
                            >
                                <div className="videoIconWrapper disabled">
                                    <img alt="microsoft team icon" src="./assets/google-meet.svg" />
                                    <span>Google Meet</span>
                                </div>
                            </OverlayTrigger>

                        </div>
                    </div>

                    <button className='primary-btn' style={{ width: "100%" }} onClick={handleJoinButtonClick}>
                        Connect
                    </button>
                </Form>

                <MultiConnectionWarningModal
                    show={showMultipleConnectionsWarning}
                    handleClose={() => setShowMultipleConnecctionsWarning(false)}
                    handleShareScreen={props.startCasting} />

                <WrongMeetingRoomModal
                    show={showWrongMeetingRoomWarning}
                    handleClose={() => setShowWrongMeetingRoomWarning(false)} />

            </div >
        </div >

    )

}